import React from "react";
import './../staking.css';
import { routeNames } from '../routes'
import { ReactComponent as Logo } from './../media/logo-footer.svg';
import { ReactComponent as Twitter } from './../media/twitter.svg';
import { ReactComponent as Discord } from './../media/discord.svg';
import { ReactComponent as Telegram } from './../media/telegram.svg';
import { useNavigate } from "react-router-dom";

function Footer() {
    const navigate = useNavigate();
    return <div className="footer-container">
        <div className="footer-content"><Logo onClick={() => navigate(routeNames.dashboard)} />
            <div className="f-vertical-line"></div>
            <div className="footer-icons">
                <a href="https://twitter.com/MultiFights" target="_blank" rel="noreferrer"><Twitter /></a>
                <a href="https://discord.com/invite/du8urb6pd6" target="_blank" rel="noreferrer"><Discord /></a>
                <a href="https://t.me/multifights" target="_blank" rel="noreferrer"><Telegram /></a>
            </div>
        </div>
    </div>
}

export default Footer;