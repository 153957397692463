import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { AxiosInterceptorContext, DappProvider } from '@multiversx/sdk-dapp/wrappers';
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import { routeNames, routes } from './routes';
import { Layout } from './components/Layout';
import LoginPage from './pages/Login';
import PageNotFound from './pages/PageNotFound'

function App() {
  return (
    <AxiosInterceptorContext.Provider>
      <AxiosInterceptorContext.Interceptor
        authenticatedDomanis={['https://tools.multiversx.com']}
      >
    <Router>
      <DappProvider
        environment="mainnet"
        customNetworkConfig={{
          name: 'customConfig',
          walletConnectV2ProjectId: 'c7abf993e449e768fa89064b77cc2230',
        }}
      >
        <Layout>
        <AxiosInterceptorContext.Listener />
        <TransactionsToastList />
        <NotificationModal />
        <SignTransactionsModals className='custom-class-for-modals' />
          <Routes>
            <Route path={routeNames.login} element={<LoginPage />} />
                {routes.map((route, index) => (
                  <Route
                    path={route.path}
                    key={'route-key-' + index}
                    element={
                      <route.component type={route.options?.type} sc_address={route.options?.sc_address} />
                    }
                  />
                ))}
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Layout>
      </DappProvider>
    </Router>
    </AxiosInterceptorContext.Interceptor>
    </AxiosInterceptorContext.Provider>
  );
}

export default App;
