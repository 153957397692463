import axios from 'axios'

export const COLLECTION = 'MULTIGERS-5529a5';
export const CLASSIC_SC_ADDRESS = 'erd1qqqqqqqqqqqqqpgqgkjaenrw23wnp9cssfeplpau3dmkcwt6lzms5x7pqd';
export const PLAYERS_SC_ADDRESS = 'erd1qqqqqqqqqqqqqpgq9l2rc6wx6929npnn079z7tst4u5zlmaylzmsqt5n3h';
export const MULTIVERX_API = axios.create({
    baseURL: 'https://api.multiversx.com'
})
export const STAKING_API = axios.create({
    baseURL: 'https://staking.multi-fights.com'
})
