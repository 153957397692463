import { useState } from 'react'

export const useSelectTigers = () => {
    const [tigersSelected, setTigersSelected] = useState([]);

    const insertTiger = (tiger) => {
        if(exists(tiger.identifier)) {
            setTigersSelected(tigersSelected.filter((item) => item.identifier !== tiger.identifier));
        } else {
            setTigersSelected((prev) => ([...prev, tiger]))
        }
    }

    const selectAll = (tigers) => {
        if(tigers.length >= 50) {
            setTigersSelected(tigers.slice(0,50));
            return;
        }
        setTigersSelected(tigers)
    }

    const deselectAll = () => {
        setTigersSelected([]);
    }

    const exists = (identifier) => {
        const tigersMap = new Map(tigersSelected.map((item) => ([item.identifier, item])));
        return tigersMap.has(identifier);
    }

    return {
        tigersSelected,
        insertTiger,
        exists,
        selectAll,
        deselectAll
    }
}
