/* eslint-disable react/prop-types */
import React from "react";
import '../staking.css';
// import InfoIc from "../media/icon-info.svg";

function StakingTitle({ type, titleName, toggle, btnName }) {
    return <div>
        <div className="staking-title-container">
            <h3 className="white big-title">{titleName}</h3>
            {
                type && type !== 'players' && (
                    <div className={`stake-button`} onClick={toggle}>
                        <h3 className="white med-text">{btnName}</h3>
                    </div>
                )
            }
        </div>
        {
            type && type !== 'players' && (
                <h3 className="grey" style={{ marginBottom: '-10px' }}>The period for <span className="white">unbond</span> the NFTs is 3 days!</h3>
            )
        }
    </div>
}



export default StakingTitle;

