import React from "react";
import TigerGif from './../media/police_tiger.png';
import './../staking.css';
import { ReactComponent as DashboardIcon } from './../media/dashboard.svg';
import { Link } from 'react-router-dom'

function PageNotFound() {
    return <div className="page-not-found-container">
        <div className="page-not-found-content">
            <img src={TigerGif} alt="" className="tiger-n-found" />
            <h3 className="big-title white"><span className="orange">Hands Up!</span> What are you doing here?</h3>
            <Link to="" className="market-button"><DashboardIcon/><h3 className="big-title">Dashboard</h3></Link>
        </div>
    </div>
}

export default PageNotFound;