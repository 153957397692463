/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

function MufEmission({ data }) {
    const [emission, setEmission] = useState(0);

    useEffect(() => {
        if(!data) return
        if(!data.players.distribution_daily) return;
        if(!data.classic.distribution_daily) return;
        const total = Number(data.players.distribution_daily) + Number(data.classic.distribution_daily)
        setEmission(total)
    }, [data])

    return <div className="total-nfts">
        <h3 className="main-title white-gradient">{(emission).toLocaleString('en-EN')}</h3>
        <h3 className="main-subtitle grey">$MUF emission / day</h3>
    </div >;
}

export default MufEmission;