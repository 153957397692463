import { useGetAccountInfo, useGetActiveTransactionsStatus, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import useSWR from 'swr';
import { COLLECTION, MULTIVERX_API } from '../config';
import { useEffect } from 'react';
import { globalDelay } from '../utils/globalDelay';

const getTigers = async (address) => {
  try {
    const { data } = await MULTIVERX_API.get(`/accounts/${address}/nfts?collection=${COLLECTION}&size=500`);
    return data;
    // eslint-disable-next-line no-empty
  } catch { }
}

export const useGetTigers = () => {
  const isLoggedIn = useGetIsLoggedIn();
  const { address } = useGetAccountInfo();
  const { success } = useGetActiveTransactionsStatus();
  const { data: tigers = [], error, isLoading, mutate } = useSWR(isLoggedIn && '/api/user/tiger', () => getTigers(address));

  useEffect(() => {
    if(success === true) globalDelay(mutate)
  }, [success])

  return {
    tigers,
    error,
    isLoading
  }
}
