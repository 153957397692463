import React from "react";
import './../../staking.css';
import Info from './components/Info';
import SelectStaking from "./components/SelectStaking";
import { routeNames } from "../../routes";
import { ReactComponent as IconClaw } from './../../media/icon-claw.svg';
import { ReactComponent as IconClaw2 } from './../../media/icon-claw2.svg';


function Dashboard() {
    return <div className="dashboard-content">
        <Info />
        <div className="dashboard-hline"></div>
        <div className="select-staking-container">
            <SelectStaking
                Icon={IconClaw}
                stakingName="Classic"
                path={routeNames.classic}
            />
            <SelectStaking
                stakingName="Players"
                Icon={IconClaw2}
                path={routeNames.players}
            />
        </div>
    </div>;
}

export default Dashboard;