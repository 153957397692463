import { refreshAccount } from '@multiversx/sdk-dapp/utils'
import { sendTransactions } from '@multiversx/sdk-dapp/services'
import { numberToHex } from "../../utils/numberToHex";

export const unbond = async (sc_address, _address, tigers) => {
    let data = 'unbond';

    for(const tiger of tigers) {
        data += `@${numberToHex(tiger.nonce)}`;
    }
    const gasLimit = 6000000 + 2000000 * tigers.length;

    await refreshAccount();
    await sendTransactions({
        transactions: {
                value: 0,
                data: data,
                receiver: sc_address,
                gasLimit
            },
    });
}