import React from "react";
import './../../staking.css';
import TigerGif from './../../media/tiger2.gif';

function NoStaked() {
    return <div className="missing-container">
        <img src={TigerGif} alt="" className="missing-tiger" />
        <h3 className="big-title white">Our <span className="orange">tiger</span> races to stake. When&apos;s your turn?</h3>
    </div>
}

export default NoStaked;