/* eslint-disable react/prop-types */
import React, { useState } from "react";
import TigerList from "../../../../components/TigerList";
import NoStaked from "../../../../components/TigerList/NoStaked";
import HorizontalLine from "../../../../components/HorizontalLine";
import StakingTitle from "../../../../components/StakingTitle";
import { unbond } from "../../../../hooks/transactions/unbond";
import CountNFTs from "../../../../components/CountNFTs";
import { useGetIsLoggedIn } from "@multiversx/sdk-dapp/hooks";
import StakingInfo from "../PlayersStaking/StakingInfo/StakingInfo";

// eslint-disable-next-line react/prop-types, no-unused-vars
function UnbondCharacters({ type, sc_address, nfts, isLoading, toggle }) {
  const isLoggedIn = useGetIsLoggedIn();
  const [tigersCount, setTigersCount] = useState(0);

  return (
    <div className="players main-staking-container">
      <StakingTitle type={type} titleName={"Waiting for unbond"} btnName={'Classic'} toggle={toggle} />
      <HorizontalLine />
      <StakingInfo type={'unbond'} sc_address={sc_address} numberOfNfts={nfts.length} />
      <TigerList
        sc_address={sc_address}
        setTigersCount={setTigersCount}
        type="unbond"
        action={unbond}
        tigers={nfts}
        isLoading={isLoading}
        MissingComponent={NoStaked}
      />
      {isLoggedIn && nfts.length > 0 && (
        <CountNFTs count={tigersCount} total={nfts.length} />
      )}
    </div>
  );
}

export default UnbondCharacters;
