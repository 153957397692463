/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import './../../../../staking.css';
import StakingInfo from './StakingInfo/StakingInfo';
import TigerList from "../../../../components/TigerList";
import NoStaked from "../../../../components/TigerList/NoStaked";
import HorizontalLine from "../../../../components/HorizontalLine";
import StakingTitle from "../../../../components/StakingTitle";
import { unStake } from "../../../../hooks/transactions/unStake";
import CountNFTs from "../../../../components/CountNFTs";
import { useGetIsLoggedIn } from "@multiversx/sdk-dapp/hooks";
import { CLASSIC_SC_ADDRESS } from "../../../../config";

// eslint-disable-next-line react/prop-types, no-unused-vars
function PlayersStaking({ type, sc_address, nfts, isLoading, toggle }) {
    const isLoggedIn = useGetIsLoggedIn();
    const [tigersCount, setTigersCount] = useState(0);

    return <div className="players main-staking-container">
        <StakingTitle
            type={type}
            titleName={sc_address === CLASSIC_SC_ADDRESS ? "Classic Staking" : "Players Staking"}
            btnName={'Unbond'}
            toggle={toggle}
        />
        <HorizontalLine />
        <StakingInfo type={type} sc_address={sc_address} numberOfNfts={nfts.length} />
        <TigerList sc_address={sc_address} setTigersCount={setTigersCount} type="unstake" action={unStake} tigers={nfts} isLoading={isLoading} MissingComponent={NoStaked} />
        {(isLoggedIn && nfts.length > 0) && <CountNFTs count={tigersCount} total={nfts.length} />}
    </div>;
}

export default PlayersStaking;