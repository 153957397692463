/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

function TotalNfts({ data }) {
    const [nftsCount, setNftsCount] = useState(0);
    
    useEffect(() => {
        if(!data) return
        if(!data.players.distribution_daily) return;
        if(!data.classic.distribution_daily) return;
        const total = Number(data.players.staked_nfts_count) + Number(data.classic.staked_nfts_count)
        setNftsCount(total)
    }, [data])

    return <div className="total-nfts">
        <h3 className="main-title white-gradient">{nftsCount}</h3>
        <h3 className="main-subtitle grey">Total #NFTs Staked</h3>
    </div >;
}

export default TotalNfts;