/* eslint-disable react/prop-types */
import "../../staking.css";
import Tiger from "./Tiger";
import Loading from "./Loading";
import { useSelectTigers } from "./hooks/useSelectTigers";
import Button from "../Button";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import { useEffect } from "react";

function TigerList({
  sc_address,
  tigers,
  isLoading,
  setTigersCount,
  MissingComponent,
  buttonsPosition = "bottom",
  action,
  type = "stake",
}) {
  const { address } = useGetAccountInfo();
  const { tigersSelected, insertTiger, exists, selectAll, deselectAll } =
    useSelectTigers();

  useEffect(() => {
    if (setTigersCount) setTigersCount(tigersSelected.length);
  }, [tigersSelected]);

  if (isLoading) {
    return <Loading />;
  }

  if (tigers.length === 0) return <MissingComponent />;

  return (
    <>
      {buttonsPosition === "top" && (
        <div className="buttons-container">
          <Button bName="Select All" onClick={() => selectAll(tigers)} />
          <Button bName="Deselect All" onClick={deselectAll} />
          <Button
            bName={type === "stake" ? "Stake" : type === 'unStake' ? "UnStake" : 'unbond'}
            onClick={() => {
              action(sc_address, address, tigersSelected);
              deselectAll();
            }}
            disabled={tigersSelected.length === 0}
          />
        </div>
      )}
      <div className="tiger-list">
        {tigers.map((item, index) => (
          <Tiger
            onClick={insertTiger}
            key={index}
            tiger={item}
            exists={exists}
          />
        ))}
      </div>
      {buttonsPosition === "bottom" && (
        <div className="buttons-container">
          <Button bName="Select All" onClick={() => selectAll(tigers)} />
          <Button bName="Deselect All" onClick={deselectAll} />
          <Button
            bName={type === "stake" ? "Stake" : type === 'unStake' ? "UnStake" : 'Unbond'}
            onClick={() => {
              action(sc_address, address, tigersSelected);
              deselectAll();
            }}
            disabled={tigersSelected.length === 0}
          />
        </div>
      )}
    </>
  );
}

export default TigerList;
