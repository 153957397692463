/* eslint-disable react/prop-types */
import React from 'react'
import { routeNames } from '../routes'
import { useLocation, useNavigate } from 'react-router-dom';

export const ConnectButton = ({ onlyIcon }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if(onlyIcon) return (
    <div className="connect-wallet" onClick={() => navigate(`${routeNames.login}?callbackURL=${pathname}`)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="76" height="87" viewBox="0 0 76 87" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M34.875 44V0.25H41.125V44H34.875ZM11.0757 33.2049C8.24216 38.0135 6.74848 43.4935 6.75 49.075C6.75034 54.5607 8.19471 59.9498 10.938 64.7003C13.6812 69.4509 17.6267 73.3956 22.3778 76.138C27.1288 78.8804 32.5181 80.3238 38.0038 80.3231C43.4896 80.3224 48.8785 78.8777 53.6289 76.1342C58.3793 73.3906 62.3238 69.4449 65.0659 64.6936C67.808 59.9424 69.251 54.553 69.25 49.0673C69.249 43.5815 67.8039 38.1927 65.06 33.4424C62.3162 28.6923 58.3702 24.748 53.6187 22.0062L56.75 16.6C63.9235 20.7423 69.5237 27.1432 72.6761 34.8036C75.8285 42.4639 76.3558 50.9524 74.1757 58.944C71.9956 66.9356 67.2308 73.9804 60.6251 78.9788C54.0194 83.9771 45.9446 86.6475 37.6613 86.5731C29.378 86.4988 21.3525 83.6839 14.8375 78.5679C8.32254 73.4519 3.68488 66.3227 1.64853 58.2933C-0.387844 50.2639 0.291721 41.7861 3.58109 34.1836C6.87049 26.5811 12.5845 20.2817 19.8312 16.2687L22.8625 21.7313C17.9793 24.4343 13.9093 28.3962 11.0757 33.2049Z"/>
        </svg>
    </div>
  )

  return (
    <div className="connect-wallet" onClick={() => navigate(`${routeNames.login}?callbackURL=${pathname}`)}>
        <h3 className="med-text black">Connect</h3>
    </div>
  )
}
