import { refreshAccount } from '@multiversx/sdk-dapp/utils'
import { sendTransactions } from '@multiversx/sdk-dapp/services'

export const claim = async (sc_address) => {
    await refreshAccount();
    await sendTransactions({
        transactions: {
                value: 0,
                data: 'claim',
                receiver: sc_address,
                gasLimit: 6000000
            },
    });
}