import React from "react";
import { deadlineCountdown } from "../../../../../hooks/deadlineCountdown";

// eslint-disable-next-line react/prop-types
const Countdown = ({ deadline }) => {
  return (
    <div className="countdown">
      <h3 className="white small-text">{deadlineCountdown(deadline)}</h3>
    </div>
  );
};

export default Countdown;
