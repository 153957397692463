/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import './../../../staking.css';

function ConnectEl(props) {
    return <div className="connect-element">
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox={props.viewBox} fill="none" className="connect-icon">
            <path d={props.icon} />
        </svg>
        <h3 className="white main-button-text">{props.name}</h3>
        <div className="behind-gradient"></div>
    </div>
}

export default ConnectEl;