import React from "react";
import TotalNftsStaked from "./components/TotalNftsStaked";
import MufEmission from "./components/MufEmission";
import { useGetAnalytics } from "../../../../../hooks/useGetAnalytics";

function GeneralStats() {
    const { data } = useGetAnalytics()
    return <div className="up-stats">
        <TotalNftsStaked data={data} />
        <MufEmission data={data} />
    </div>;
}

export default GeneralStats;