/* eslint-disable react/prop-types */
import React from "react";

function AmountStaked({ type, numberOfNfts = 0 }) {
    return <div className="section-container">
        <h3 className="grey small-text">{type === 'unbond' ? 'Pending NFTs' : 'NFTs Staked'}</h3>
        <h3 className="white med-text">{numberOfNfts}</h3>
    </div>
}

export default AmountStaked;