export const deadlineCountdown = (deadline) => {
    var currentTimestamp = Math.floor(Date.now() / 1000);
    var difference = deadline - currentTimestamp;
    
    if (difference <= 0) {
        return "Ready";
    } else {
        var days = Math.floor(difference / (60 * 60 * 24));
        var hours = Math.floor((difference % (60 * 60 * 24)) / (60 * 60));
        var minutes = Math.floor((difference % ( 60 * 60)) / 60);
        
        return days + " days " + hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0');
    }
}