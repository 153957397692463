import useSWR from 'swr';
import { STAKING_API } from '../config';

const getAnalytics = async () => {
  try {
    const { data } = await STAKING_API.get('/analytics');
    return data;
    // eslint-disable-next-line no-empty
  } catch { }
}

export const useGetAnalytics = () => {
  const { data, error, isLoading } = useSWR('/api/analytics',() => getAnalytics());

  return {
    data,
    error,
    isLoading
  }
}
