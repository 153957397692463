/* eslint-disable react/prop-types */
import React from "react";
import './../../../../../staking.css';
import Apr from './components/Apr'
import AmountStaked from "./components/NftStaked";
import Reward from "./components/Reward";
import ClassicIcon from './../../../../../media/icon-claw.png';

function StakingInfo({ type, sc_address, numberOfNfts }) {
    return <div className="info-container">
        <div className="player-staking">
            <img src={ClassicIcon} alt="" className="icon-p-staking" />
        </div>
        <div className="vertical-line"></div>
        <Apr type={type} />
        <div className="vertical-line"></div>
        <AmountStaked type={type} numberOfNfts={numberOfNfts} />
        <div className="vertical-line"></div>
        <Reward type={type} sc_address={sc_address} />
    </div>;
}

export default StakingInfo;