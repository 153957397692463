import useSWR from 'swr';
import { STAKING_API } from '../config';

const getApr = async (type) => {
  if(type === 'unbond') return 0;
  try {
    const { data } = await STAKING_API.get(`/${type}/apr`);
    return data.apr;
    // eslint-disable-next-line no-empty
  } catch { }
}

export const useGetApr = (type) => {
  const { data: apr = 0, error, isLoading } = useSWR(`/api/${type}/apr`, () => getApr(type));

  return {
    apr,
    error,
    isLoading
  }
}
