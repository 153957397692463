import React from "react";
import { useGetReward } from "../../../../../../hooks/useGetReward";
import { useGetIsLoggedIn } from "@multiversx/sdk-dapp/hooks";
import { ConnectButton } from '../../../../../../components/ConnectButton'
import { claim } from "../../../../../../hooks/transactions/claim";

// eslint-disable-next-line react/prop-types
function Reward({ type, sc_address }) {
    const isLoggedIn = useGetIsLoggedIn();
    const { data } = useGetReward(type);
    return <>
        <div className="section-container">
            <h3 className="grey small-text">$MUF Earned</h3>
            <h3 className="white med-text">{Number(data).toFixed(2)}</h3>
        </div>
        <div className="vertical-line"></div>
        {
            isLoggedIn ? (
                <div className={`stake-button ${type === 'unbond' && 'button-disabled'}`} onClick={type !== 'unbond' ? () => claim(sc_address) : () => {}}>
                    <h3 className="white med-text">Claim</h3>
                </div>
            ) : (
                <ConnectButton onlyIcon />
            )
        }
    </>
}

export default Reward;