import React from "react";
import './../staking.css';
import LogoWhite from './../media/logo.svg';
import { useGetAccountInfo, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks'
import { logout } from '@multiversx/sdk-dapp/utils'
import { useNavigate } from "react-router-dom";
import { routeNames } from "../routes";
import { truncateAddress } from "../utils/truncateAddress";
import { ConnectButton } from "./ConnectButton";

function NavBar() {
    const isLoggedIn = useGetIsLoggedIn();
    const { address } = useGetAccountInfo();
    const navigate = useNavigate();

    return <div className="navbar">
        <div className="navbar-elements">
            <img src={LogoWhite} alt="" className="logo-navbar" onClick={() => navigate(routeNames.dashboard)} />
            <div className="wallet-connect">
                {
                    isLoggedIn ? (
                        <>
                            <h3 className="med-text grey">{truncateAddress(address, 6, -3)}</h3>
                            <div className="disconnect-wallet" onClick={() => logout(routeNames.dashboard)}>
                                <h3 className="med-text grey">Logout</h3>
                            </div>
                        </>
                    ) : (
                        <ConnectButton />
                    )
                }
            </div>
        </div>
    </div>;
}

export default NavBar;