/* eslint-disable react/prop-types */
import React from "react";
import "../../staking.css";
import Countdown from "../../pages/StakingPage/components/UnbondCharacters/components/Countdown";

function Tiger({ tiger, onClick, exists }) {
  return (
    <div
      className={`tiger-container ${exists(tiger.identifier) && "selected"}`}
      onClick={() => onClick(tiger)}
    >
      <div className="ID">
        <h3 className="white small-text">{tiger.name.slice(9)}</h3>
      </div>
      {tiger.deadline && <Countdown deadline={tiger.deadline} />}
      <img src={tiger.url} alt="" className="tiger-staked" />
    </div>
  );
}

export default Tiger;
