import React from "react";
import './../../../../staking.css';
import GeneralStats from "./GeneralStats";

function Info() {
    return <div className="first-content">
        <div className="title-subtitle">
            <h3 className="main-title white"><span className="orange">MULTIFIGHTS</span> Staking</h3>
            <h3 className="main-subtitle grey">This project includes two smart contract types: one rewards players with a higher APR and in-game rewards, incentivizing active participation, while the other lets users temporarily access NFTs without purchase, offering investors even higher APR incentives.</h3>
        </div >
        <GeneralStats />
    </div>;
}

export default Info;