import React from "react";
import { useGetApr } from "../../../../../../hooks/useGetApr";

// eslint-disable-next-line react/prop-types
function Apr({ type }) {
    const { apr } = useGetApr(type);

    return <div className="section-container">
        <h3 className="grey small-text">Staking Apr</h3>
        <h3 className="white med-text">{type === 'unbond' ? 'NONE' : `${Number(apr).toLocaleString('en-EN')}%`}</h3>
    </div>
}

export default Apr;