import React, { useEffect } from "react";
import './../../staking.css';
import ConnectEl from "./components/ConnectElement";
import {
    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton
} from '@multiversx/sdk-dapp/UI'

import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks'
import { useNavigate, useSearchParams } from "react-router-dom";
import { routeNames } from '../../routes'

const icons = [
    {
        path: "M23.5417 37.6548V40.1846H40V28.7747H37.5119V37.6548H23.5417ZM23.5417 0V2.52992H37.5119V11.4105H40V0H23.5417ZM18.9709 11.4105H16.4829V28.7741H27.7041V26.4922H18.9709V11.4105ZM0 28.7747V40.1851H16.4581V37.6548H2.48796V28.7747H0ZM0 0V11.4105H2.48796V2.52992H16.4581V0H0Z",
        width: "40",
        height: "41",
        viewBox: "0 0 40 41",
        name: "Ledger",
    },
    {
        path: "M36.0317 7.52359H6.25001C5.91848 7.52359 5.60053 7.39148 5.36612 7.15631C5.13171 6.92116 5.00001 6.60222 5.00001 6.26966C5.00001 5.93709 5.13171 5.61815 5.36612 5.383C5.60053 5.14783 5.91848 5.01573 6.25001 5.01573H36.25C36.5816 5.01573 36.8994 4.88362 37.1339 4.64845C37.3683 4.4133 37.5 4.09436 37.5 3.7618C37.5 2.7641 37.105 1.80727 36.4017 1.1018C35.6984 0.396331 34.7446 0 33.75 0H5.00001C3.67392 0 2.40215 0.528434 1.46446 1.46907C0.526788 2.4097 0 3.68548 0 5.01573V30.0943C0 31.4246 0.526788 32.7003 1.46446 33.641C2.40215 34.5816 3.67392 35.11 5.00001 35.11H36.0317C37.0547 35.1371 38.0466 34.7563 38.7903 34.0514C39.5341 33.3463 39.9691 32.3743 40 31.3482V11.2854C39.9691 10.2593 39.5341 9.28737 38.7903 8.58233C38.0466 7.87727 37.0547 7.49658 36.0317 7.52359ZM14.8 24.8762L14.8117 24.8612L11.25 28.4258V14.2145L18.3317 21.3268L25.4167 14.2145V28.4258L21.875 24.8762L18.3334 28.4258L14.8 24.8762ZM36.428 21.9495C36.428 23.1627 35.4476 24.1464 34.238 24.1464C33.0286 24.1464 32.048 23.1627 32.048 21.9495C32.048 20.7361 33.0286 19.7526 34.238 19.7526C35.4476 19.7526 36.428 20.7361 36.428 21.9495Z",
        width: "40",
        height: "36",
        viewBox: "0 0 40 36",
        name: "MultiversX Web Wallet",
    },
    {
        path: "M4.03033 4.03033C2.39384 5.66682 1.75 8.49464 1.75 13.5V38.5C1.75 43.5054 2.39384 46.3332 4.03033 47.9697C5.66682 49.6062 8.49464 50.25 13.5 50.25H28.5C33.5054 50.25 36.3332 49.6062 37.9697 47.9697C39.6062 46.3332 40.25 43.5054 40.25 38.5V13.5C40.25 8.49464 39.6062 5.66682 37.9697 4.03033C36.3332 2.39384 33.5054 1.75 28.5 1.75H13.5C8.49464 1.75 5.66682 2.39384 4.03033 4.03033ZM2.96967 2.96967C5.08318 0.856164 8.50536 0.25 13.5 0.25H28.5C33.4946 0.25 36.9168 0.856164 39.0303 2.96967C41.1438 5.08318 41.75 8.50536 41.75 13.5V38.5C41.75 43.4946 41.1438 46.9168 39.0303 49.0303C36.9168 51.1438 33.4946 51.75 28.5 51.75H13.5C8.50536 51.75 5.08318 51.1438 2.96967 49.0303C0.856164 46.9168 0.25 43.4946 0.25 38.5V13.5C0.25 8.50536 0.856164 5.08318 2.96967 2.96967ZM17.7301 36.6047C18.5975 35.7373 19.7739 35.25 21.0005 35.25C22.2271 35.25 23.4035 35.7372 24.2708 36.6047L17.7301 36.6047ZM24.2708 36.6047C25.1382 37.4721 25.6255 38.6483 25.6255 39.875C25.6255 41.1017 25.1382 42.2779 24.2708 43.1453V36.6047ZM21.0005 36.75C20.1717 36.75 19.3768 37.0792 18.7908 37.6653C18.2047 38.2514 17.8755 39.0462 17.8755 39.875C17.8755 40.7038 18.2047 41.4986 18.7908 42.0847C19.3768 42.6708 20.1717 43 21.0005 43C21.8293 43 22.6241 42.6708 23.2101 42.0847L23.2102 42.0847C23.7963 41.4985 24.1255 40.7038 24.1255 39.875C24.1255 39.0462 23.7963 38.2514 23.2102 37.6653L23.2101 37.6653C22.6241 37.0792 21.8293 36.75 21.0005 36.75ZM28.5 15.8221L21.793 19.7253L28.5 23.579L27.367 25.9505L21.2493 23.2826C21.068 23.2332 20.8867 23.2332 20.7507 23.2826L14.6329 26L13.5 23.6285L20.207 19.7747L13.5 15.8715L14.6329 13.5L20.7507 16.168C20.932 16.2174 21.1133 16.2174 21.2493 16.168L27.367 13.5L28.5 15.8221Z",
        width: "42",
        height: "52",
        viewBox: "0 0 42 52",
        name: "xPortal Mobile Wallet",
    },
    {
        path: "M36.0317 7.52359H6.25001C5.91848 7.52359 5.60053 7.39148 5.36612 7.15631C5.13171 6.92116 5.00001 6.60222 5.00001 6.26966C5.00001 5.93709 5.13171 5.61815 5.36612 5.383C5.60053 5.14783 5.91848 5.01573 6.25001 5.01573H36.25C36.5816 5.01573 36.8994 4.88362 37.1339 4.64845C37.3683 4.4133 37.5 4.09436 37.5 3.7618C37.5 2.7641 37.105 1.80727 36.4017 1.1018C35.6984 0.396331 34.7446 0 33.75 0H5.00001C3.67392 0 2.40215 0.528434 1.46446 1.46907C0.526788 2.4097 0 3.68548 0 5.01573V30.0943C0 31.4246 0.526788 32.7003 1.46446 33.641C2.40215 34.5816 3.67392 35.11 5.00001 35.11H36.0317C37.0547 35.1371 38.0466 34.7563 38.7903 34.0514C39.5341 33.3463 39.9691 32.3743 40 31.3482V11.2854C39.9691 10.2593 39.5341 9.28737 38.7903 8.58233C38.0466 7.87727 37.0547 7.49658 36.0317 7.52359ZM14.8 24.8762L14.8117 24.8612L11.25 28.4258V14.2145L18.3317 21.3268L25.4167 14.2145V28.4258L21.875 24.8762L18.3334 28.4258L14.8 24.8762ZM36.428 21.9495C36.428 23.1627 35.4476 24.1464 34.238 24.1464C33.0286 24.1464 32.048 23.1627 32.048 21.9495C32.048 20.7361 33.0286 19.7526 34.238 19.7526C35.4476 19.7526 36.428 20.7361 36.428 21.9495Z",
        width: "40",
        height: "36",
        viewBox: "0 0 40 36",
        name: "MultiversX Defi Wallet",
    },
];

function LoginPage() {
    const isLoggedIn = useGetIsLoggedIn();
    const navigate = useNavigate();
    const [urlSearchParams] = useSearchParams();
    const callbackURL = urlSearchParams.get('callbackURL');
    const props = {
        callbackRoute: callbackURL,
        nativeAuth: true
    }

    useEffect(() => {
        if(isLoggedIn && (!callbackURL) ) navigate(routeNames.dashboard)
        if(isLoggedIn && callbackURL) navigate(callbackURL)
    }, [isLoggedIn])

    return <div className="connect-page-container">
        <div className="connect-page-el">
            <h3 className="white main-title"><span className="orange">Connect</span> Wallet</h3>

            <LedgerLoginButton className="login-btn" {...props} >
                <ConnectEl
                    icon={icons[0].path}
                    width={icons[0].width}
                    height={icons[0].height}
                    viewBox={icons[0].viewBox}
                    name={icons[0].name}
                />
            </LedgerLoginButton>
            <WebWalletLoginButton className="login-btn" {...props} >
                <ConnectEl
                    icon={icons[1].path}
                    width={icons[1].width}
                    height={icons[1].height}
                    viewBox={icons[1].viewBox}
                    name={icons[1].name}
                />
            </WebWalletLoginButton>
            <WalletConnectLoginButton className="login-btn" {...props} isWalletConnectV2={true}>
                <ConnectEl
                    icon={icons[2].path}
                    width={icons[2].width}
                    height={icons[2].height}
                    viewBox={icons[2].viewBox}
                    name={icons[2].name}
                />
            </WalletConnectLoginButton>
            <ExtensionLoginButton className="login-btn" {...props} >
                <ConnectEl
                    icon={icons[3].path}
                    width={icons[3].width}
                    height={icons[3].height}
                    viewBox={icons[3].viewBox}
                    name={icons[3].name}
                />
            </ExtensionLoginButton>
            <div className="connect-bg-image"></div>
        </div>
    </div>
}

export default LoginPage;