import React, { useState } from "react";
import UnbondCharacters from "./components/UnbondCharacters";
import PlayersStaking from "./components/PlayersStaking";
import AvailableForStake from "./components/AvailableForStake";
import { useGetTigersStaked } from "../../hooks/useGetTigersStaked";

// eslint-disable-next-line react/prop-types
function StakingPage({ type, sc_address }) {
    const [showUnbond, setShowUnbond] = useState(false);
    const { nfts, isLoading } = useGetTigersStaked(type);

    const handleToggleShowUnbond = () => setShowUnbond(!showUnbond);

    return (
        <div className="staking-main-container">
            <div className="bg-image"></div>
            <div className="staking-container">
                {
                    showUnbond ? (
                        <UnbondCharacters
                            type={type}
                            sc_address={sc_address}
                            nfts={nfts.filter((nft) => nft.deadline !== undefined)}
                            isLoading={isLoading}
                            toggle={handleToggleShowUnbond}
                        />) : (
                            <PlayersStaking
                                type={type}
                                sc_address={sc_address}
                                nfts={type === 'classic' ? nfts.filter((nft) => !nft.deadline) : nfts}
                                isLoading={isLoading}
                                toggle={handleToggleShowUnbond}
                            />)
                }
                <AvailableForStake type={type} sc_address={sc_address} />
            </div>
        </div>
    )
}

export default StakingPage;
