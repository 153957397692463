import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';


// eslint-disable-next-line react/prop-types
export const Layout = ({ children }) => {
  return (
    <div>
        <NavBar />
        {children}
        <Footer />
    </div>
  );
};
