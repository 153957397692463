/* eslint-disable no-undef */
import { Address } from '@multiversx/sdk-core/out';
import { refreshAccount } from '@multiversx/sdk-dapp/utils'
import { sendTransactions } from '@multiversx/sdk-dapp/services'
import { numberToHex } from "../../utils/numberToHex";

export const stake = async (sc_address, address, tigers) => {
    let data = 'MultiESDTNFTTransfer@'
    + new Address(sc_address).hex()
    + '@' + numberToHex(tigers.length);

    for(const tiger of tigers) {
        data += `@${Buffer.from(tiger.collection).toString("hex")}@${numberToHex(tiger.nonce)}@${numberToHex(1)}`;
    }

    data += "@" + Buffer.from('stake').toString("hex");
    const gasLimit = 6000000 + 2000000 * tigers.length;

    await refreshAccount();
    await sendTransactions({
        transactions: {
                value: 0,
                data: data,
                receiver: address,
                gasLimit
            },
    });
}