import React from "react";
import "../staking.css";

// eslint-disable-next-line react/prop-types
function CountNFTs({ count, total }) {
  return (
    <div className="count-nfts">
      <h3 className="black med-text">{count}</h3>
      <h3 className="black med-text">/</h3>
      <h3 className="black med-text">{total}</h3>
    </div>
  );
}

export default CountNFTs;
