import React, { useState } from "react";
import './../../../../staking.css';
import HorizontalLine from "../../../../components/HorizontalLine";
import StakingTitle from "../../../../components/StakingTitle";
import TigerList from "../../../../components/TigerList";
import MissingTiger from "../../../../components/TigerList/MissingTiger";
import CountNFTs from "../../../../components/CountNFTs";
import { useGetTigers } from "../../../../hooks/useGetTigers";
import { stake } from "../../../../hooks/transactions/stake";
import { useGetIsLoggedIn } from "@multiversx/sdk-dapp/hooks";

// eslint-disable-next-line react/prop-types
function AvailableForStake({ sc_address }) {
    const isLoggedIn = useGetIsLoggedIn();
    const { tigers, isLoading } = useGetTigers()
    const [tigersCount, setTigersCount] = useState(0);

    return <div className="af-stake main-staking-container">
        <StakingTitle
            titleName="Available for Stake"
        />
        <HorizontalLine />
        <TigerList
            sc_address={sc_address}
            action={stake}
            tigers={tigers}
            isLoading={isLoading}
            setTigersCount={setTigersCount}
            MissingComponent={MissingTiger}
            buttonsPosition='top'
        />
        {(isLoggedIn && tigers.length > 0) && <CountNFTs count={tigersCount} total={tigers.length} />}
    </div>;
}

export default AvailableForStake;