import { useGetAccountInfo, useGetActiveTransactionsStatus, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import useSWR from 'swr';
import { STAKING_API } from '../config';
import { useEffect } from 'react';
import { globalDelay } from '../utils/globalDelay';

const getTigersStaked = async (type, address) => {
  try {
    const { data } = await STAKING_API.get(`/${type}/${address}`);
    return data.nfts;
    // eslint-disable-next-line no-empty
  } catch {
    console.log('error')
   }
}

export const useGetTigersStaked = (type) => {
  const isLoggedIn = useGetIsLoggedIn();
  const { address } = useGetAccountInfo();
  const { success } = useGetActiveTransactionsStatus();
  const { data: nfts = [], error, isLoading, mutate } = useSWR(isLoggedIn && `/api/${type}/${address}`, () => getTigersStaked(type, address));

  useEffect(() => {
    if(success === true) globalDelay(mutate, 2000)
  }, [success])

  return {
    nfts,
    error,
    isLoading
  }
}
