/* eslint-disable react/prop-types */
import React from "react";
import './../../../staking.css';
import { useNavigate } from "react-router-dom";

function SelectStaking({ Icon, stakingName, path, disabled = false }) {
    const navigate = useNavigate();

    return <div className={disabled ? "disabled" : "select-staking"} onClick={disabled ? () => {} : () => navigate(path)}>
        {
            Icon && <Icon className="select-staking-icon" />
        }
        <h3 className="black main-button-text">{stakingName}</h3>
    </div>
}


export default SelectStaking;