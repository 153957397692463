import {
  CLASSIC_SC_ADDRESS, PLAYERS_SC_ADDRESS,
} from './config';
import StakingPage from './pages';
import Dashboard from './pages/Dashboard';

export const routeNames = {
  dashboard: '/',
  classic: '/classic',
  players: '/players',
  login: '/login'
};

export const routes = [
  {
    path: routeNames.classic,
    component: StakingPage,
    authenticatedRoute: true,
    options: {
      type: 'classic',
      sc_address: CLASSIC_SC_ADDRESS,
    }
  },
  {
    path: routeNames.players,
    component: StakingPage,
    authenticatedRoute: true,
    options: {
      type: 'players',
      sc_address: PLAYERS_SC_ADDRESS,
    }
  },
  {
    path: routeNames.dashboard,
    component: Dashboard,
    authenticatedRoute: true
  },
];