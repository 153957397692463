import React from "react";
import './../../staking.css';
import TigerGif from './../../media/tiger.gif';
import { ReactComponent as TigerClaw } from './../../media/icon-claw.svg';
import { useGetIsLoggedIn } from "@multiversx/sdk-dapp/hooks";
import { ConnectButton } from "../ConnectButton";
import { routeNames } from "../../routes";

function MissingTiger() {
    const isLogeedIn = useGetIsLoggedIn();

    return <div className="missing-container">
        <img src={TigerGif} alt="" className="missing-tiger" />
        <h3 className="big-title white"><span className="orange">Oops! </span>It seems like there are no NFTs here. 🙁</h3>
        {
            isLogeedIn ? (
                <div className="market-button">
                    <TigerClaw />
                    <a href="https://www.frameit.gg/marketplace/MULTIGERS-5529a5/mint?sp=true" target="_blank" rel="noreferrer" className="link"><h3 className="black">Check this</h3></a>
                </div>
            ) : (
                <ConnectButton callbackURL={routeNames.classic} />
            )
        }
    </div>
}

export default MissingTiger;